import './App.css';
import Chart from 'react-apexcharts';
import Apex from 'apexcharts';
import moment from 'moment';
import { Icon, Button, InputGroup, Spinner } from '@blueprintjs/core';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLazyQuery, gql } from '@apollo/client';

const PROMO_REPORT = gql`
  query PromoReport($email: String!, $promo: String!) {
    promoReport(email: $email, promo: $promo) {
      name
      total
      report {
        date
        value
      }
    }
  }
`;

interface PromoReport {
  name: string;
  total: number;
  report: {
    date: string;
    value: number;
  }[];
}
interface PromoReportData {
  promoReport: PromoReport;
}
interface PromoReportVar {
  email: string;
  promo: string;
}

const PromoSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  promo: Yup.string().required('Required'),
});

interface ChartData {
  series: ApexAxisChartSeries;
  options: Apex.ApexOptions;
}

const handleChart = (data: PromoReport) => {
  const chartData: ChartData = {
    series: [
      {
        name: data.name,
        data: data.report.map((x: any) => x.value),
      },
    ],
    options: {
      chart: {
        type: 'line',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      title: {
        text: `Total ${data.name} Redemptions ${data.total}`,
        align: 'left',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      yaxis: {
        min: 0,
        max: Math.ceil(
          data.report.reduce((max: number, x) => {
            return x.value > max ? x.value : max;
          }, 0) * 1.2,
        ),
      },
      xaxis: {
        categories: data.report.map((x) => moment(x.date).format('DD-MM')),
      },
    },
  };
  return chartData;
};

function App() {
  const [getPromoReport, { loading, data }] = useLazyQuery<
    PromoReportData,
    PromoReportVar
  >(PROMO_REPORT);
  const [chartData, setChartData] = useState<ChartData>();

  const formik = useFormik({
    initialValues: {
      email: '',
      promo: '',
    },
    validationSchema: PromoSchema,
    onSubmit: (values) => {
      getPromoReport({
        variables: {
          email: values.email,
          promo: values.promo.toUpperCase(),
        },
      });
      formik.setSubmitting(false);
    },
  });

  useEffect(() => {
    if (!loading && data) {
      const update = handleChart(data.promoReport);
      setChartData(update);
    }
  }, [loading, data]);

  return (
    <div className="App">
      <header className="App-header">
        <p>Referral Dashboard</p>
        <form onSubmit={formik.handleSubmit} className="form">
          <InputGroup
            type="email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            intent={
              formik.errors.email && formik.touched.email ? 'danger' : 'none'
            }
            value={formik.values.email}
            leftIcon="user"
            placeholder="Email address..."
          />
          {formik.errors.email && formik.touched.email && formik.errors.email}
          <InputGroup
            type="text"
            name="promo"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            intent={
              formik.errors.promo && formik.touched.promo ? 'danger' : 'none'
            }
            value={formik.values.promo.toUpperCase()}
            leftIcon="tag"
            placeholder="Promo code..."
          />
          <Button
            type="submit"
            disabled={!!formik.errors.email || !!formik.errors.promo || loading}
            fill>
            {loading ? <Spinner size={Icon.SIZE_STANDARD} /> : 'Submit'}
          </Button>
        </form>
        {chartData && (
          <div id="chart">
            <Chart
              options={chartData.options}
              series={chartData.series}
              type="line"
              height={450}
              width={700}
            />
          </div>
        )}
      </header>
    </div>
  );
}

export default App;
